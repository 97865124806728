<template>
  <div class="dashboard">
    <DefaultLayout>
      <PageTitle title="REQUESTS.TITLE" subtitle="REQUESTS.SUBTITLE">
        <!-- <Button>
          <span class="fs-16">{{ $t("GLOBAL.EXPORT_REPORTS") }}</span>
          <img class="mx-2" src="@/assets/images/icons/export.svg" />
        </Button> -->
      </PageTitle>
      <div class="requests-filter">
    <div class="requests-filter__status">
      <div class="all" :class="requestStatus == 'all' && 'selected'" @click="setStatus('all')">
        <p class="mb-0 fs-18">{{ $t("REQUESTS.ALL") }}</p>
      </div>
      <div
        class="active"
        :class="requestStatus == 2 && 'selected'"
        @click="setStatus(2)"
      >
        <img src="@/assets/images/icons/active.svg" />
        <p class="mb-0 fs-18">{{ $t("REQUESTS.ACTIVE") }}</p>
        <p class="mb-0 fs-18 count">{{ active_count }}</p>
      </div>
      <div
        class="completed"
        :class="requestStatus == 3 && 'selected'"
        @click="setStatus(3)"
      >
        <img src="@/assets/images/icons/active.svg" />
        <p class="mb-0 fs-18">{{ $t("REQUESTS.COMPLETED") }}</p>
        <p class="mb-0 fs-18 count">{{ complete_count }}</p>
      </div>
      <div
        class="pending"
        :class="requestStatus == 1 && 'selected'"
        @click="setStatus(1)"
      >
        <img src="@/assets/images/icons/pending.svg" />
        <p class="mb-0 fs-18">{{ $t("REQUESTS.PENDING") }}</p>
        <p class="mb-0 fs-18 count">{{ pending_count }}</p>
      </div>
      <div
        class="canceled"
        :class="requestStatus == 0 && 'selected'"
        @click="setStatus(0)"
      >
        <img src="@/assets/images/icons/canceled.svg" />
        <p class="mb-0 fs-18">{{ $t("REQUESTS.CANCELED") }}</p>
        <p class="mb-0 fs-18 count">{{ cancle_count }}</p>
      </div>
    </div>
    <div class="requests-filter__date">
      <Button type="submit" @click="dateFilter()">{{ $t("REQUESTS.SUBMIT") }}</Button>
      <b-form-group class="mb-0">
        <DateTimePicker
          v-model="filterDateRange"
          :config="{ enableTime: false, mode: 'range' }"
        />
      </b-form-group>
    </div>
  </div>
      <div v-if="orders.length > 0">
        <Request
          v-for="order in orders"
          :key="order.id"
          :order="order"
          :loading="loading"
          @viewRequest="handleViewRequest"
          @proceedRequest="handleProceedRequest"
        />
      </div>
      <div class="alert alert-danger" v-else>
        No Orders
      </div>

      <div class="overflow-auto">
        <!-- {{ requestStatus }} -->
    <!-- Use text in props -->
    <CustomPagination :current_page="current_page" :requestStatus="requestStatus" :links="links" @paginatePrev="paginatePrev" @paginateNext="paginateNext" @getPagination="getPagination"/>
    
    </div>
      <!-- <Pagination @onPageChange="onPageChange" :current-page="current_page" :total-items="total_items" :per-page="per_page" class="pagination mt-3" /> -->
    </DefaultLayout>
    <div class="side-form-container">
      <b-sidebar id="sidebar-form" width="60em" v-model="showSideForm" shadow no-header>
        <div class="side-form">
          <div class="side-form__header">
            <div class="title">
              <h3 v-if="requstActionType == 'proceed'">{{ $t("REQUESTS.PROCEED_REQUEST") }}</h3>
              <h3 v-else>{{ $t("REQUESTS.VIEW_REQUEST") }}</h3>
              <p v-if="requstActionType == 'proceed'">
                {{ $t("REQUESTS.PROCEED_REQUEST_SUBTITLE") }}
              </p>
              <p v-else>{{ $t("REQUESTS.VIEW_REQUEST_SUBTITLE") }}</p>
            </div>
            <div class="close-btn" @click="handleCloseSideForm">
              <img src="@/assets/images/icons/close-btn.svg" />
              <p>{{ $t("GLOBAL.CLOSE") }}</p>
            </div>
          </div>
          <div class="side-form__content" :class="requstActionType == 'view' && 'disable-function'">
            <FormValidation ref="observer" @handleSubmit="onSubmit">
              <b-form>
                <b-row>
                  <b-col>
                    <b-form-group class="mb-3">
                      <TextField
                        v-model="request.operation_number"
                        :label="$t('REQUESTS.REQUEST_NUMBER')"
                        :name="$t('REQUESTS.REQUEST_NUMBER')"
                        rules="required"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group class="mb-3">
                      <TextField
                        v-model="request.pieces_count"
                        :label="$t('REQUESTS.PIECES_NUMBER')"
                        :name="$t('REQUESTS.PIECES_NUMBER')"
                        rules="required"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mb-3">
                  <div class="payment">
                    <h6>{{ $t("REQUESTS.PAYMENT_METHOD") }}</h6>
                    <div class="payment-way">
                      {{ request.payment_method_id == 2?$t("REQUESTS.CASH_ON_DELIVERY"):$t("REQUESTS.ONLINE_PAYMENT") }}
                    </div>
                  </div>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group class="mb-3">
                      <TextField
                        v-model="request.weight"
                        :label="$t('REQUESTS.WEIGHT')"
                        :name="$t('REQUESTS.WEIGHT')"
                        rules="required"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group class="mb-3">
                      <TextField
                        v-model="request.transportation"
                        :label="$t('REQUESTS.CAR_TYPE')"
                        :name="$t('REQUESTS.CAR_TYPE')"
                        rules="required"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group class="mb-3">
                      <TextField
                        v-model="request.transportation_commission"
                        :label="$t('REQUESTS.TRANSPORTATION_COMMISSION')"
                        :name="$t('REQUESTS.TRANSPORTATION_COMMISSION')"
                        rules="required"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group class="mb-3">
                      <TextField
                        v-model="request.wadiily_commission"
                        :label="$t('REQUESTS.WADIILY_COMMISION')"
                        :name="$t('REQUESTS.WADIILY_COMMISION')"
                        rules="required"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group class="mb-3">
                      <SelectSearch
                        v-model="request.cityId"
                        :label="$t('REQUESTS.CITY_LOCATION')"
                        :name="$t('REQUESTS.CITY_LOCATION')"
                        :options="cities"
                        :get-option-label="(option) => option.name"
                        :reduce="(option) => option.id"
                        rules="required"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group class="mb-3">
                      <SelectSearch
                        v-model="request.transportation"
                        :label="$t('REQUESTS.CAR_SIZE')"
                        :name="$t('REQUESTS.CAR_SIZE')"
                        :options="carSizes"
                        :get-option-label="(option) => option.name"
                        :reduce="(option) => option.id"
                        rules="required"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group class="mb-3">
                      <TextField
                        v-model="request.from"
                        :label="$t('REQUESTS.PLACE_FROM')"
                        :name="$t('REQUESTS.PLACE_FROM')"
                        rules="required"
                      />
                    </b-form-group>
                    <!-- <input type="text" placeholder="Origin" ref="origin" /> -->
                  </b-col>
                  <b-col>
                    <b-form-group class="mb-3">
                      <TextField
                        v-model="request.to"
                        :label="$t('REQUESTS.PLACE_TO')"
                        :name="$t('REQUESTS.PLACE_TO')"
                        rules="required"
                      />
                    </b-form-group>
                    <!-- <input type="text" placeholder="Destination" ref="destination" /> -->
                  </b-col>
                </b-row>
                <b-row v-if="requstActionType == 'proceed'">
                  <b-col>
                    <div class="side-form__footer">
                      <Button type="submit">{{ $t("REQUESTS.SAVE_REQUEST") }}</Button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </FormValidation>
          </div>
        </div>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/Shared/PageTitle/index.vue"
import Request from "@/components/Modules/Requests/Request/index.vue"
import CustomPagination from "@/components/Shared/CustomPagination/index.vue"
import { getOrdersRequest, getOrderByIdRequest, getOrdersByPaginate, getOrdersRequestByDate } from "@/api/orders.js"
export default {
  components: {
    PageTitle,
    Request,
    CustomPagination
  },
  data() {
    return {
      loading: false,
      orders: [],
      orderData: {},
      requstActionType: "view",
      showSideForm: false,
      cities: [{ id: 1, name: "منطقة الدقي" }],
      carSizes: [{ id: 1, name: "نص نقل" }],
      requestStatus: "all",
      filterDateRange: new Date(),
      current_page: 1,
      total_items: 0,
      per_page: 0,
      pending_count: 0,
      active_count: 0,
      complete_count: 0,
      cancle_count: 0,
      links: '',
      activeLinks: '',
      request: {
        operation_number: "",
        piecesNumber: "",
        weight: "",
        car_size: "",
        transportation_commission: "",
        wadiily_commission: "",
        cityId: 1,
        car_type: "",
        placeFrom: "",
        placeTo: ""
      }
    }
  },
  mounted() {
    this.getOrders('all')
    // for (let ref in this.$refs) {
    //   const autocomplete = new google.maps.places.Autocomplete(this.$refs[ref])

    //   autocomplete.addListener("place_changed", () => {
    //     const place = autocomplete.getPlace()
    //     console.log(place)
    //   })
    // }
  },
  methods: {
    onSubmit() {
      console.log("Submit Request")
      this.showSideForm = false
    },
    getOrderData(requestId) {
      this.loading = true
      this.ApiService(getOrderByIdRequest(requestId))
        .then((res) => {
          if (res.data.status != "success") {
            this.ShowToast({
              title: this.$t("GLOBAL.GLOBAL_FAILED"),
              type: "danger",
              message: res.data.message || this.$t("GLOBAL.GLOBAL_FAILED")
            })
            return res
          }
          console.log(res.data.data)
          this.orderData = res.data.data
          this.request = this.orderData
          this.showSideForm = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleViewRequest(requestId) {
      this.requstActionType = "view"
      this.getOrderData(requestId)
    },
    handleProceedRequest(requestId) {
      this.requstActionType = "proceed"
      this.getOrderData(requestId)
    },
    handleCloseSideForm() {
      this.showSideForm = false
    },
    getOrders(status) {
      this.loading = true
      this.ApiService(getOrdersRequest(status))
        .then((res) => {
          console.log(res)
          if (res.data.status != "success") {
            this.ShowToast({
              title: this.$t("GLOBAL.GLOBAL_FAILED"),
              type: "danger",
              message: res.data.message || this.$t("GLOBAL.GLOBAL_FAILED")
            })
            return res
          }
          this.orders = res.data.orders.data
          this.current_page = res.data.orders.current_page
          this.total_items = res.data.orders.total
          this.per_page = res.data.orders.per_page
          this.links = res.data.orders.links.slice(1, res.data.orders.links.length - 1)
          this.activeLinks = this.links.slice(0, 4)
          this.pending_count = res.data.pending_count
          this.active_count = res.data.active_count
          this.complete_count = res.data.complete_count
          this.cancle_count = res.data.cancle_count
        })
        .finally(() => {
          this.loading = false
        })
    },
    setStatus(status) {
      this.requestStatus = status;
      this.getOrders(status)
    },
    dateFilter(){
      console.log(this.filterDateRange)
      this.loading = true
      this.ApiService(getOrdersRequestByDate(this.filterDateRange, 'date'))
      .then(res => {
        console.log(res.data.orders)
          this.orders = res.data.orders.data
          this.current_page = res.data.orders.current_page
          this.total_items = res.data.orders.total
          this.per_page = res.data.orders.per_page
          this.links = res.data.orders.links.slice(1, res.data.orders.links.length - 1)
          this.activeLinks = this.links.slice(0, 4)
      })
    },
    getPagination(v){
      this.loading = true
      this.ApiService(getOrdersByPaginate(v.page, v.filter))
      .then(res => {
        if(res.data.status == 'success'){
          this.orders = res.data.orders.data
          this.current_page = res.data.orders.current_page
          this.total_items = res.data.orders.total
          this.per_page = res.data.orders.per_page
          this.links = res.data.orders.links.slice(1, res.data.orders.links.length - 1)
          this.activeLinks = this.links.slice(0, 4)
          this.loading = false
        }
      })
    },
    paginateNext(v){
      if(v.c_page != this.links.length){
        this.loading = true
        this.ApiService(getOrdersByPaginate(v.c_page+1, v.filter))
        .then(res => {
          if(res.data.status == 'success'){
            this.orders = res.data.orders.data
            this.current_page = res.data.orders.current_page
            this.total_items = res.data.orders.total
            this.per_page = res.data.orders.per_page
            this.links = res.data.orders.links.slice(1, res.data.orders.links.length - 1)
            this.loading = false
          }
        })
      }else{
        return false
      }
    },
    paginatePrev(v){
      if(v.c_page != 1){
        this.loading = true
        this.ApiService(getOrdersByPaginate(v.c_page-1, v.filter))
        .then(res => {
          if(res.data.status == 'success'){
            this.orders = res.data.orders.data
            this.current_page = res.data.orders.current_page
            this.total_items = res.data.orders.total
            this.per_page = res.data.orders.per_page
            this.links = res.data.orders.links.slice(1, res.data.orders.links.length - 1)
            this.loading = false
          }
        })
      }else{
        return false
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "./index.scss";
.request__info{
  width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
</style>
