export const getOrdersRequest = (filter) => ({
  method: "get",
  url: `orders?filter=${filter}`
})

export const getOrdersRequestByDate = (filter, type) => ({
  method: "get",
  url: `orders?filter=${filter}&type=${type}`
})

export const getOrderByIdRequest = (id) => ({
  method: "get",
  url: `orders/${id}`
})

export const getOrdersByPaginate = (page, filter) => ({
  method: "get",
  url: `orders?page=${page}&filter=${filter}`
})