<template>
  <div class="request">
    <div class="request__circles">
      <div class="small-circle"></div>
      <div class="big-circle"></div>
      <div class="request__code">
        <div class="title">
          <h4 class="fs-22">{{ order.operation_number ? order.operation_number : 'رقم عملية النقل' }}</h4>
          <p class="done fs-14" v-if="order.status == 2">تمت</p>
        </div>
        <span class="price fs-14">{{ order.price }} EGP</span>
      </div>
    </div>
    <div class="request__info">
      <h6 class="fs-18">{{ $t("REQUESTS.PLACE") }}</h6>
      <p class="fs-18">{{ order.from }} - {{ order.to }}</p>
    </div>
    <div class="request__info">
      <h6 class="fs-18">{{ $t("REQUESTS.DRIVER") }}</h6>
      <p class="fs-18">{{ order.driver ? order.driver.name : '' }}</p>
    </div>
    <div class="request__info">
      <h6 class="fs-18">{{ $t("REQUESTS.INCOME") }}</h6>
      <p class="fs-18">3000 فقط لا غير</p>
    </div>
    <div class="request__info">
      <h6 class="fs-18">{{ $t("REQUESTS.PAYMENT_METHOD") }}</h6>
      <p class="fs-18" v-if="order.payment_method_id == 1">{{ $t("REQUESTS.ONLINE_PAYMENT") }}</p>
      <p class="fs-18" v-else>{{ $t("REQUESTS.CASH_ON_DELIVERY") }}</p>
    </div>
    <div class="request__status">
      <div v-if="order.status == 1" class="status-circle pending">
        <img src="@/assets/images/icons/pending.svg" />
        <p class="fs-16">{{ $t("REQUESTS.PENDING_STATUS") }}</p>
      </div>
      <div v-else-if="order.status == 2" class="status-circle active">
        <img src="@/assets/images/icons/active.svg" />
        <p class="fs-16">{{ $t("REQUESTS.ACTIVE_STATUS") }}</p>
      </div>
      <div v-else-if="order.status == 3" class="status-circle completed">
        <img src="@/assets/images/icons/active.svg" />
        <p class="fs-16">{{ $t("REQUESTS.COMPLETED_STATUS") }}</p>
      </div>
      <div v-else class="status-circle canceled">
        <img src="@/assets/images/icons/canceled.svg" />
        <p class="fs-16">{{ $t("REQUESTS.CANCLED_STATUS") }}</p>
      </div>
    </div>
    <Button @click="handleViewRequest(order.id)">{{ $t("REQUESTS.SHOW_REQUEST_DETAILS") }}</Button>
    <Button @click="handleProceedRequest(order.id)" >{{ $t("REQUESTS.PROCEED_REQUEST") }}</Button>
  </div>
</template>
<script>
export default {
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    handleViewRequest(id) {
      this.$emit('viewRequest', id)
    },
    handleProceedRequest(id) {
      this.$emit('proceedRequest', id)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>